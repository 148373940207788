<template>
  <popupCharge :activation="charge" v-if="charge"/>
  <div class="p-10 pr-16 all">
    <div class="pt-20 mt-6">
      <div
        class="flex items-center"
      >
        <div class="w-full flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            @click="retour"
            original
          />
          <div class="ml-4 w-3/5">
            {{ info.bankName }}
          </div>
        </div>
      </div>

      <div class="mt-6 pl-4">
        <stats :donnes="info"/>

        <fiche class="mt-16"/>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import stats from '../../component/cdevs/banqueDetail/stats'
import fiche from '../../component/cdevs/banqueDetail/ficheCaisse'
import popupCharge from '../../component/popup/popupCharge'

export default {
  name: "Index",

  components: {
    stats,
    fiche,
    popupCharge
  },

  data () {
    return {
      icons: {
        back,
      },
      info: null,
      statData: null,
      charge: false,
    }
  },

  created () {
    this.info = this.$store.getters.trackTemporal
  },

  methods: {
    retour() {
      window.history.back()
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}
</style>
