<template>
  <div class="w-full">
    <div
        class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer"
    >
      <div class="w-1/6 text-c13">
        {{ new Date(item.created_at).toLocaleString() }}
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.method === 'CASH' || item.method === 'BANK_TRANSFER'">Espèce</span>
        <span v-if="item.method === 'MOBILE_MONEY'">Mobile Money</span>
        <span v-if="item.method === 'BANK_CHECK'">Chèque</span>
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.type === 'DEBIT'">-</span>
        {{ item.amount.toLocaleString() }} F
      </div>

      <div class="w-1/6 ml-4">
        <bouton
            label="Débit"
            height="30px"
            color="#F82626"
            background="#FEE9E9"
            radius="10px"
            size="12px"
            v-if="item.type === 'DEBIT'"
            class="w-1/2"
        />
        <bouton
            label="Crédit"
            height="32px"
            color="#00C24E"
            background="#E5F9ED"
            radius="10px"
            size="12px"
            v-if="item.type === 'CREDIT'"
            class="w-1/2"
        />
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.type === 'CREDIT'">
          <span v-if="item.action === 'RECEIVE_WITHDRAW_AMOUNT' || item.action === 'WITHDRAW_AMOUNT'">
            -
          </span>

          <span v-if="item.action === 'BANK_TRANSFER'">
            <span v-if="item.transfer">
              <span v-if="!item.transfer.isNoSender">COMPTABILITÉ</span>
              <span v-if="item.transfer.isNoSender">{{ item.transfer.senderName }}</span>
            </span>
            <span v-if="!item.transfer">
              -
            </span>
          </span>

          <span v-if="item.action === 'DEPOSIT_IN_BANK'">
              <span v-if="item.deposit">
                <span v-if="item.deposit.person">
                  <span v-if="item.deposit.person.user.type === 'SELL'">AGENCE -</span>
                   <span v-if="item.deposit.person.user.type !== 'SELL'">COMPTABILITÉ</span>
                </span>
              </span>
              <span v-if="!item.deposit"> - </span>
          </span>
        </span>

        <span v-if="item.type === 'DEBIT'">
          <span v-if="item.action === 'RECEIVE_WITHDRAW_AMOUNT' || item.action === 'WITHDRAW_AMOUNT'">
            <span v-if="item.wallet">{{ item.wallet.bankName }}</span>
            <span v-if="!item.wallet"> - </span>
          </span>

           <span v-if="item.action === 'BANK_TRANSFER'">
            {{ item.wallet.bankName }}
          </span>

           <span v-if="item.action === 'EXPENSE'">
            {{ item.wallet.bankName }}
          </span>

        </span>

      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.type === 'CREDIT'">
          <span v-if="item.action === 'DEPOSIT_IN_BANK'">
             {{ item.wallet.bankName }}
           </span>

          <span v-if="item.action === 'BANK_TRANSFER'">
             {{ item.wallet.bankName }}
           </span>

          <span v-if="item.action === 'RECEIVE_WITHDRAW_AMOUNT' || item.action === 'WITHDRAW_AMOUNT'">
             {{ item.wallet.bankName }}
          </span>
        </span>

        <span v-if="item.type === 'DEBIT'">
          <span v-if="item.action === 'DEPOSIT_IN_BANK'">
            <span v-if="item.deposit">
                 <span v-if="item.deposit.receive_bank">{{item.deposit.receive_bank.bankName}}</span>
            </span>
            <span v-if="!item.deposit"> - </span>
          </span>

          <span v-if="item.action === 'BANK_TRANSFER'">
            <span v-if="item.transfer">
              <span v-if="item.transfer.receiver">{{ item.transfer.receiver.bankName }}</span>
            </span>
            <span v-if="!item.transfer">
              -
            </span>
          </span>

          <span v-if="item.action === 'RECEIVE_WITHDRAW_AMOUNT' || item.action === 'WITHDRAW_AMOUNT'">
            {{ item.withdraw.person.fullname }}
          </span>

            <span v-if="item.action === 'EXPENSE'">
              COMPTABILITÉ
          </span>
        </span>

      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.action === 'DEPOSIT_IN_BANK'">Remise en banque</span>
        <span v-if="item.action === 'BANK_TRANSFER'">Virement bancaire</span>
        <span v-if="item.action === 'RECEIVE_WITHDRAW_AMOUNT' || item.action === 'WITHDRAW_AMOUNT'">
          <span v-if="!item.withdraw.meta.destinataire">Retrait</span>
          <span v-if="item.withdraw.meta.destinataire"> {{ item.withdraw.meta.destinataire }}</span>
        </span>
        <span v-if="item.action === 'EXPENSE'">
          <span v-if="item.expense.supplier">
            {{ item.expense.supplier.name }}
          </span>
        </span>
      </div>

      <div class="w-1/6 pl-4">
        <span v-if="item.action === 'RECEIVE_WITHDRAW_AMOUNT' || item.action === 'WITHDRAW_AMOUNT'">
          {{ item.withdraw.num }}
        </span>

        <span v-if="item.action === 'DEPOSIT_IN_BANK'">
          {{ item.deposit.num }}
        </span>
        <span v-if="item.action === 'BANK_TRANSFER'">
          <span v-if="item.transfer">{{ item.transfer.code }}</span>
        </span>

        <span v-if="item.action === 'EXPENSE'">
            {{ item.expense.reference }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'
import down from '../../../assets/icons/down.svg'
import bouton from '../../helper/add/button'

export default {
  name: "Index",
  components: {
    bouton,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        entreprise,
        email,
        down
      },
    }
  },

  computed: {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}

</style>
