<template>
  <div class="">
    <div>
      <div
          class="flex"
      >
        <div class="w-1/3 h-20 flex text-left text-c24 items-center font-c6">
          Opérations du compte
        </div>

        <div class="w-2/3">
          <div class="flex justify-end w-full">
            <div class="w-1/5 ml-4">
              <bouton
                  :icon="icons.calendar"
                  iconHeight="17"
                  iconWidth="17"
                  height="54px"
                  label="Période"
                  color="black"
                  size="14px"
                  radius="5px"
                  class="w-full"
                  background="white"
                  border="1px solid #DCDCE4"
                  @info="activePeriode =! activePeriode"
              />
            </div>

            <div class="w-1/5 ml-4">
              <multiselect
                  :icon="icons.filter"
                  height="54px"
                  background="white"
                  :icon-width="25"
                  value-t="Type"
                  :option="optionType"
              />
            </div>

            <div class="w-1/5 ml-4">
              <multiselect
                  :icon="icons.filter"
                  height="54px"
                  background="white"
                  :icon-width="25"
                  value-t="Moyen"
                  :option="optionMoyen"
              />
            </div>

            <bouton label="Imprimer la fiche" class="w-1/3 ml-4" @info="goto('/transfere')"/>
          </div>
        </div>
      </div>

      <div class="flex justify-end items-center text-c16 text-90 w-full mt-6">
        <div class="flex justify-end items-center w-2/4" v-if="activePeriode">
          <div>Du</div>
          <datepicker height="51px" class="w-1/3 ml-4" placeholder=""/>
          <div class="ml-6">Au</div>
          <datepicker height="51px" class="w-1/3 ml-4" placeholder=""/>
        </div>
      </div>

      <div class="mt-10">
        <global-view/>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import search from '../../../assets/icons/search.svg'
import calendar from '../../../assets/icons/calendar.svg'
import filter from '../../../assets/icons/filter.svg'
import multiselect from '../../../component/helper/form/multiselect'
import datepicker from '../../../component/helper/form/datePicker'
import globalView from './globalData'
import bouton from '../../helper/add/button'

export default {
  name: "Index",

  components: {
    globalView,
    multiselect,
    bouton,
    datepicker
  },

  data () {
    return {
      icons: {
        back,
        search,
        calendar,
        filter
      },
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
      optionType: ['Transfère', 'Remise'],
      optionMoyen: ['Mobile money', 'Espèce', 'Chèque'],
      activePeriode: false
    }
  },

  created () {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
</style>
