<template>
  <div class="text-left">
    <div class="flex mt-6">
      <div class="w-3/5 item flex bg-white p-6">
        <icon
            :data="icons.wallet"
            height="45"
            width="45"
            class="cursor-pointer"
            original
        />

        <div class="w-full ml-3">
          <div class="text-c16 text-90 w-full"> Solde Total </div>
          <div class="text-c24 w-full font-c5">
            {{ refactorAmount(donnes.amount, '.') }}
            <span class="text-90">FCFA</span>
          </div>

          <div class="h-45 w-1/2 mt-8">
            <operationButton class="h-full" :donnes="donnes"/>
          </div>

        </div>
      </div>

      <div class="w-2/5 ml-5">
        <div class="w-full item bg-white p-8 pb-16">
          <div class="flex">
            <icon
                :data="icons.banque"
                height="45"
                width="45"
                class="cursor-pointer"
                original
            />

            <div class="ml-4">
              <div class="text-c20 font-c6">{{ donnes.bankName }}</div>
<!--              <div class="text-c11 text-90">-->
<!--                - -->
<!--              </div>-->
<!--              <div class="text-c11 text-90 mt-2">-->
<!--                - -->
<!--              </div>-->
            </div>
          </div>

          <div class="h-1 bg-f5 mt-6"/>

          <div class="mt-6">
            <div class="flex items-center">
              <icon
                  :data="icons.transaction"
                  height="35"
                  width="35"
                  class="cursor-pointer"
                  original
              />

              <div class="ml-4">
                <span class="text-c14">Nombre de transactions :</span>
                <span class="text-c18 font-c6 ml-2">
                  {{ donnes.transactions.length }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banque from '../../../assets/icons/banque.svg'
import wallet from '../../../assets/icons/wall.svg'
import transaction from '../../../assets/icons/transaction.svg'
import arrow from '../../../assets/icons/arrow.svg'
import operationButton from '../banques/operationButton'

export default {
  name: "Index",
  components: {
    operationButton
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        wallet,
        banque,
        arrow,
        transaction
      }
    }
  },

  created () {
    console.log('les stts')
    console.log(this.donnes)
  },

  methods: {
    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      let c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (let i = a.length-1; i >= 0; i--) {
        c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.degrad {
  background: linear-gradient(180deg, #F6E6C6 0%, rgba(251, 167, 5, 0) 100%);
}
</style>
